import { useLoaderStore, useUserStore } from "@/stores";
import { utcToLocaleDateTime } from "@/utils/dateFormat.util.ts";
import { removeTZforISOString } from "./time.utils";

export const regexPasswordValidation =
  /(.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*)/;

export const getObjKey = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

/**
 * Returns the initials of the first and last names from a given full name.
 * @param name - The full name from which to extract the initials.
 * @returns The initials of the first and last names.
 */

export const firstLastInitials = (name: string) => {
  const word = name.split(" ").filter((item) => item !== "");
  const firstName = word.shift();
  const lastName = word.pop();
  if (firstName && lastName) {
    return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  } else {
    return firstName?.substring(0, 2);
  }
};

/**
 * Split by character and get the first item
 */

export const splitAndGetFirstItem = (text: string, character: string) => {
  return text.split(character)[0];
};

export const getLatLngObjFromString = (latLong: string) => {
  if (latLong) {
    const latLngArr = latLong.split(",");
    return {
      lat: Number(latLngArr[0]),
      lng: Number(latLngArr[1]),
    };
  }
  return null;
};
export const splitAndMakeUpperCase = (text: string) => {
  if (text === "request_rejected") {
    const diffText = text.split("_");
    return (
      diffText[0].charAt(0).toUpperCase() +
      diffText[0].slice(1) +
      " " +
      diffText[1]
    );
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const rowPerPage = 10;
export const rowsPerPageOptions = [10, 20, 30, 50];

// convert number to 2 decimal places and if the 3rd decimal is greater than 5 2nd decimal will be increased by 1
export const convertToTwoDecimal = (number) => {
  const roundedNumber = Math.round(number * 100) / 100; // Round to 2 decimal places
  const decimals = (roundedNumber % 1).toFixed(3).split("."); // Extract decimals

  if (parseInt(decimals[1][2]) >= 5) {
    const adjustedNumber = Math.round((roundedNumber + 0.01) * 100) / 100; // Increase 2nd decimal by 0.01
    return adjustedNumber.toFixed(2);
  }

  return roundedNumber.toFixed(2);
};

export const countCharactersWithoutSpaces = (inputString: string) => {
  const stringWithoutSpaces = inputString.replace(/\s/g, "");

  // Count the number of characters in the string without spaces
  return stringWithoutSpaces.length;
};

// map auto location input
export const searchCountries = ["no", "se", "dk"];

export const termsHtmlFerdia = `
<p>Welcome to the BusNetwork Portal, powered by Ferdia. These Terms &amp; Conditions set the rules for using our software and services. Please read this document carefully, as signing up and using the portal means you agree.</p>

<h4>Acceptance of Terms </h4>
<p>By accessing and using this portal, you, agree to be bound by these Terms and Conditions, our Privacy Policy, and any other legal notices published by us. If you do not agree to these terms, please do not use our services.</p>

<h4>Account setup </h4>
<p>To use the portal, create an account with accurate info. Keep it updated!</p>

<p>You're responsible for your account's security and activity. Don't share your password!</p>

<h4>Use of the software</h4>
<p> This portal is for sharing bus trips and capacity between operators and subcontractors.</p>

<p>Use it only for legal purposes and follow these rules.</p>

<h4>Data Protection and Privacy </h4>
<p>We are committed to protecting your privacy and personal information.</p>

<p>You agree to comply with all applicable data protection laws in relation to any personal data you access through our portal.</p>

<h4>Intellectual Property Rights</h4>
<p> All intellectual property rights in the portal, including software, design, text, images, and other content are owned by Ferdia.</p>

<p>Your use of the portal does not grant you ownership of any intellectual property rights in the portal or the content you access.</p>

<h4>Limitations of Liability </h4>
<p>Ferdia will not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the BusNetwork Portal.</p>

<h4>Indemnification</h4>
<p> You agree to indemnify and hold harmless Ferdia and its officers, directors, employees, and agents from any claims, damages, liabilities, costs, and expenses (including attorney&rsquo;s fees) arising from or related to your use of the portal.</p>

<h4>Modification of Terms</h4>
<p> Ferdia reserves the right, at our discretion, to modify these Terms and Conditions at any time. Your continued use of the portal after any such changes constitutes your acceptance of the new Terms and Conditions.</p>

<h4>Governing Law </h4>
<p>These Terms and Conditions shall be governed and construed in accordance with the laws of Norway, without regard to its conflict of law provisions.</p>

<h4>Need help?</h4>
<p> For quick answers, check our handy in-portal guides covering frequently asked questions.</p>

<p>For more specific questions or expert assistance, please contact your partner bus company; they are best equipped to address questions related to your specific partnership and account.</p>
<hr/>
<h4>Acknowledgment</h4>

<p>By confirming you have read this document you acknowledge that you have read, understood, and that you agree to be bound by the above Terms and Conditions.</p>

`;

export const showLoader = (value = true) => {
  useLoaderStore().changeLoadingStatus({ isLoading: value });
};

export const userCompanyId = () => useUserStore().getUserCompanyId;

/**
 * Generates a unique key by combining a timestamp and a random substring.
 *
 * @returns {string} A unique key.
 * @example
 * const uniqueKey = generateUniqueKey();
 * console.log(uniqueKey);
 */
export const generateUniqueKey = () => {
  /**
   * @type {string} timestamp - Current timestamp converted to base36.
   */
  const timestamp = new Date().getTime();

  /**
   * @type {string} randomPart - Random alphanumeric substring.
   */
  const randomPart = Math.random().toString(36).substring(2, 7);

  /**
   * @type {string} uniqueKey - The unique key formed by concatenating the timestamp and randomPart.
   */
  const uniqueKey = `${timestamp}${randomPart}`;

  return uniqueKey;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/*
 * Eligible for start trip
 * User can not start the trip before 2 hours of trip start time
 * */

export const isEligibleForStartTrip = (tripStartTime: string) => {
  if (!tripStartTime) return false;
  const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
  //convert local time to UTC
  const isoNow = new Date().toISOString();
  // convert utc time to oslo time
  const now = new Date(
    utcToLocaleDateTime(removeTZforISOString(isoNow))
  ).valueOf();
  // Convert trip time to oslo time
  const plannedStart = new Date(utcToLocaleDateTime(tripStartTime)).valueOf();

  // Check if the current time is within 2 hours before the planned start time or any time after the planned start time
  return now >= plannedStart - TWO_HOURS_IN_MS;
};

/**
 * Decodes a base64 encoded string into a file and initiates a download.
 * @param {string} base64Data - The base64 encoded string representing the file content.
 * @param {string} fileName - The name to be given to the downloaded file.
 * @param {string} fileType - The MIME type of the file (e.g., 'text/csv').
 */
export const downloadBase64File = (
  base64Data: string,
  fileName: string,
  fileType: string
) => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

/**
 * Generates a specific error message based on the provided error data object.
 *
 * @param {Object} data - The error data object containing arrays of different error types.
 * @returns {string} A formatted error message indicating the specific type and count of errors.
 */
export const getTripActionErrorMessage = (data) => {
  const errorTypes = [
    "submitted_claims",
    "previously_submitted_claims",
    "non_existing_requests",
    "invalid_requests",
    "expired_claims",
  ];

  for (const type of errorTypes) {
    if (data[type] && data[type].length > 0) {
      return `We encountered ${data[type].length} ${type.replace(
        /_/g,
        " "
      )}. Please check and try again.`;
    }
  }

  return "An error occurred. Please try again.";
};

/**
 * Returns the current date and time formatted as 'YYYY-MM-DD HH-MM-SS'.
 *
 * @returns {string} The formatted current date and time.
 */
export const getCurrentDateTimeForFile = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
};

/**
 * The threshold for punctuality, set to -900 seconds (15 minutes).
 * This value is used to determine if a trip is considered punctual or not.
 * (-) minus value indicate we can accept 15 min late
 */
export const punctualityThreshold = -900; // 15 minutes in seconds

/**
 * Delays the execution for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to delay.
 * @returns {Promise<void>} A promise that resolves after the specified delay.
 */
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
